<template>
  <div class="option-list-item-container flex flex-wrap bg-black rounded-lg mb-2 relative p-3">
    <div v-if="option.isRecommended" class="is-recommended uppercase bg-green-600 absolute text-3xs top-0 right-0 p-1 px-2 font-bold rounded-tr-lg rounded-bl-lg">
      Recommended
    </div>
    <div class="top flex w-full">
      <div class="icon flex min-w-[40px]">
        <SvgIcon
          :icon="option.icon"
          height="30"
          width="30"
          class="m-auto"
        />
      </div>
      <div class="info px-3 flex-grow whitespace-nowrap text-ellipsis overflow-hidden">
        <div class="title text-sm font-semibold">
          {{ option.title }}
        </div>
        <div class="message text-slate-100 text-sm whitespace-nowrap text-ellipsis overflow-hidden">
          {{ option.message }}
        </div>
        <button type="button" class="read-more flex text-blue-500" @click.prevent.stop="handleToggleDetails">
          <span>
            Read More
          </span>
          <SvgIcon
            icon="arrow-down-thin"
            height="20"
            width="20"
            class="transition my-auto ml-1"
            :class="{ 'rotate-180': !showDetails }"
          />
        </button>
      </div>
      <button type="button" class="rhs flex ml-auto min-w-[60px]" @click.prevent="emits('select')">
        <SvgIcon
          icon="arrow-circle"
          height="20"
          width="20"
          class="ml-auto my-auto text-blue-500"
        />
      </button>
    </div>
    <div v-if="showDetails" class="bottom flex flex-wrap py-3 px-2 w-full text-sm text-slate-light transition">
      <div class="mb-1">
        {{ option.details.message }}
      </div>
      <ul v-if="option.details?.list" class="list">
        <li
          v-for="item in option.details.list"
          :key="item"
          class="flex py-1"
        >
          <SvgIcon
            icon="tick"
            height="20"
            width="20"
            class="my-auto text-green-500"
          />
          <span class="ml-2 flex-1 font-semibold">
            {{ item }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
defineProps({
  option: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['select',]);

const showDetails = ref(false);

function handleToggleDetails() {
  showDetails.value = !showDetails.value;
}
</script>
