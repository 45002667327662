<template>
  <div class="section-no-wallet bg-slate-900 flex rounded-md p-4">
    <div class="lhs flex flex-col">
      <div class="title font-bold text-md mb-1">
        Do not have a wallet?
      </div>
      <div class="message text-xs text-slate-100">
        Switch to custodial wallet to deposit via crypto adress
      </div>
    </div>
    <div class="rhs flex">
      <button
        type="button"
        class="bg-slate-800 py-3 px-5 rounded-md my-auto hover:bg-[#5F677C]"
        @click.prevent="emits('select')"
      >
        Switch
      </button>
    </div>
  </div>
</template>
<script setup>
const emits = defineEmits(['select',]);
</script>
