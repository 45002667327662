<template>
  <div class="options-container">
    <h4 class="title mb-1 font-bold">
      Make your First Deposit
    </h4>
    <div class="message text-sm text-slate-light">
      Thanks for choosing to deposit at Metawin. We offer 3 fast and easy ways to deposit:
    </div>
    <div class="options mt-4">
      <WalletFirstDepositOptionsListItem
        v-for="option in options"
        :key="option.title"
        :option="option"
        @select="emits('select', option.subTab)"
      />
    </div>
  </div>
</template>

<script setup>
const emits = defineEmits(['select',]);

const options = ref([
  {
    title: 'Web3 Wallet',
    message: 'Metamask, WalletConnect, Coinbase and more',
    icon: 'web3',
    subTab: 'web3',
    isRecommended: true,
    details: {
      message: 'Enjoy our unique Web3 offering giving you:',
      list: [
        'Instant and frictionless withdrawals up to $100k daily plus your deposits.',
        'Access to HUGE multiplayer on-chain crypto prize draws.',
        'Deposited funds remain under your control and stay in a decentralized wallet.',
      ],
    },
  },
  {
    title: 'Transfer Crypto',
    message: 'BTC, ETH, USDC, Tether and more',
    icon: 'crypto',
    subTab: 'custodial',
    isRecommended: false,
    details: {
      message: 'Deposit funds into your very own MetaWin wallet address from your chosen exchange.',
      list: [
        'World class, secure payments infrastructure powered by Fireblocks.',
        '25 Crypto Currencies supported on networks including Bitcoin, Tron, Sol and Eth.',
        'Fast and easy KYC/AML system (required when thresholds are met).',
      ],
    },
  },
  {
    title: 'Card Deposit',
    message: 'Visa, Mastercard, Apple Pay, Google Pay',
    icon: 'card',
    subTab: 'cards',
    isRecommended: false,
    details: {
      message: 'Make you deposit via a range of the most popular credit and debit cards using our card deposit option.',
      list: [
        'Powered by Moonpay the world’s largest crypto on-ramp.',
        'Buy and play with your preferred crypto instantly using any major payment card.',
        'Previous MoonPay users require no further KYC.',
      ],
    },
  },
]);
</script>
