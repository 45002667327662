<template>
  <div class="rounded-md bg-slate-900/90 backdrop-blur-sm font-bold z-20 flex flex-col justify-center items-center text-center px-6 text-sm">
    <h4 class="text-xl mb-2">
      Email Verification Required
    </h4>
    <p class="mb-4">{{ message }}</p>
    <ButtonButton
      theme="primary"
      @click.prevent="handleRedirectToSettings"
    >
      Verify Now
    </ButtonButton>
  </div>
</template>

<script setup>
defineProps({
  message: {
    type: String,
    required: true,
  },
});

const emits = defineEmits([
  'close',
]);

const router = useRouter();

function handleRedirectToSettings() {
  emits('close');
  return router.push({ path: '/account', });
}
</script>
