<template>
  <div
    class="absolute z-10 top-0 right-0 bottom-0 left-0 bg-slate-900/90 backdrop-blur-sm rounded-md flex flex-col items-center justify-center text-sm font-bold"
  >
    <div>
      Switch network on your wallet to continue
    </div>
    <ButtonButton
      theme="google"
      size="sm"
      class="mt-4"
      @click.prevent="emits('switch-network')"
    >
      <SvgIcon
        :icon="logo"
        width="16"
        height="16"
        class="mr-1"
      />
      Switch to {{ network }}
    </ButtonButton>

    <ButtonButton
      v-if="!error"
      size="xs"
      class="mt-20"
      @click.prevent="emits('close')"
    >
      Close
    </ButtonButton>
    <div v-if="error" class="text-red text-center text-sm my-4 w-[60%]">Your wallets current network is not supported. Please switch network to continue.</div>
  </div>
</template>

<script setup>
defineProps({
  logo: {
    type: String,
    required: true,
  },
  network: {
    type: String,
    required: true,
  },
  error: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  'switch-network',
  'close',
]);
</script>
