<template>
  <div class="custodial-withdraw-container">
    <div class="form-content">
      <VForm
        v-slot="{ values }"
        ref="SwapForm"
        :validation-schema="validationSchema"
        class="relative transition-all"
      >
        <div v-if="!isWalletAddressOptionsLoading" class="text-center relative" :class="{ 'opacity-40': formDisabled || currentBalance.value === 0 || disableFormActions }">
          <DropdownMain
            v-if="walletAddressOptions.length > 0 && !custodialWithdrawalAddress"
            :options="walletAddressOptions"
            :active-option="selectedWalletAddressOption"
            full
            clearable
            :disabled="formDisabled || currentBalance.value === 0 || disableFormActions"
            theme="input-normal"
            class="w-full text-left mb-2"
            @select="handleSelectWalletAddressOption"
          >
            <span class="text-gray-300">{{ selectedWalletAddressOption.text }}</span>
          </DropdownMain>
        </div>
        <template v-else>
          <div class="flex justify-center min-h-[52px] items-center">
            <Spinner :has-logo="false" size="xs"/>
          </div>
        </template>
        <InputValidated
          :class="{ 'opacity-40': formDisabled || currentBalance.value === 0 || disableFormActions}"
          name="custodialWithdrawalAddress"
          type="text"
          margin="mt-2"
          autofocus
          placeholder="Enter wallet address"
          :value="custodialWithdrawalAddress"
          :disabled="formDisabled || currentBalance.value === 0 || disableFormActions"
          :hide-error-messages="formDisabled"
          :show-validation-tick="isCustodialWithdrawalValid"
          center-align-errors
          @input="handleAddressChange(values)"
        />

        <InputValidated
          v-if="selectedCurrency?.code === 'XRP' || selectedCurrency?.code === 'EOS'"
          :class="{ 'opacity-40': formDisabled || currentBalance.value === 0 || disableFormActions}"
          name="custodialWithdrawalTag"
          type="text"
          margin="mt-2"
          :placeholder="`Enter Destination ${ selectedCurrency?.code === 'EOS' ? 'Memo' : 'Tag' } (Optional)`"
          :value="custodialWithdrawalTag"
          :disabled="formDisabled || currentBalance.value === 0 || disableFormActions"
          center-align-errors
          @input="handleTagChange(values)"
        />

        <p class="text-center my-4">
          Available Balance:
          <span class="text-blue-500 font-semibold">
            {{ currentBalance.value }} {{ selectedCurrency?.baseCode || selectedCurrency?.code }}
          </span>
        </p>

        <div class="relative" :class="{ 'opacity-40': formDisabled || currentBalance.value === 0 || disableFormActions}">
          <InputValidated
            name="transferAmount"
            type="number"
            step="0.01"
            placeholder="0"
            :max-decimal-places="selectedCurrency?.decimals || 8"
            hide-validation-tick
            :value="transferAmount"
            :disabled="formDisabled || currentBalance.value === 0 || disableFormActions"
            :currency="selectedCurrency?.code"
            :hide-error-messages="formDisabled"
            hide-validation-cross
            center-align-errors
            @keypress="$preventLetterInput($event)"
            @input="handleTranferAmountChange(values)"
          />
          <span
            class="absolute block right-2 top-[2px] bg-transparent z-10 text-slate-100 select-none py-3 pr-1"
          >
            ~${{ transferAmountOutput }}
          </span>
          <div
            v-if="transferAmount && selectedCurrency?.factor > 1"
            class="text-center mb-3 text-xs text-gray-400 -mt-1 font-bold"
          >
            X {{ selectedCurrency?.factor }} = {{ $formatMoney(totalTransferAmount) }} {{ selectedCurrency?.code?.toUpperCase() }}
          </div>
        </div>
      </VForm>

      <WalletTransferOutput
        :tx-amount="transferAmount || 0"
        :fee="fee"
        :output-amount="transferOutputAmount || 0"
        :currency-code="selectedCurrency?.code"
        :currency-code-fiat="selectedCurrency?.code"
        class="!py-0"
      />

      <ButtonButton
        :is-loading="isLoading"
        type="submit"
        :disabled="!canSubmit"
        :ignore-disabled-style="isLoading"
        class="block w-full mt-4"
        @click.prevent.stop="handleSubmitCustodialWithdrawl"
      >
        Withdraw
      </ButtonButton>

      <div class="messages">
        <div v-if="emailVerificationRequired" class="content py-4 px-6 flex flex-col gap-4 overflow-y-scroll scrollbar-hide">
          <WalletMessageTransfersRequireEmail
            :message="withdrawalAvailabilityDetails.detail"
            @close="emits('close')"
          />
        </div>
        <WalletMessagesWithdrawal
          :is-loading="isLoading"
          :error-message="errorMessage"
          :selected-currency="selectedCurrency"
          :tx-hash="txHash"
          :can-user-withdraw="canUserWithdraw"
          :withdrawal-details="withdrawalAvailabilityDetails"
          is-withdraw
          :transfer-amount="transferAmount"
          :network="activeNetworkOption?.name"
          :is-withdrawal-complete="isWithdrawalComplete"
          :is-withdrawal-processing="isWithdrawalProcessing"
          @check-if-withdrawal-available="handleFetchIsWithdrawalAvailable"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { Form as VForm } from 'vee-validate';
import * as yup from 'yup';
import BigNumber from 'bignumber.js';

import { useBankingStore } from '@/store/banking';
import { useCryptoStore } from '@/store/crypto';
import { useUiStore } from '@/store/ui';
import { useUserStore } from '@/store/user';
import { useLocationStore } from '@/store/location';
import { useWebsocketStore } from '@/store/websocket';

import { useMessageHandler } from '@/composables/useMessageHandler';
import { useWallet } from '@/composables/useWallet';

const props = defineProps({
  selectedCurrency: {
    type: Object,
    required: true,
  },
  currentBalance: {
    type: Object,
    default: null,
  },
  activeNetworkOption: {
    type: Object,
    default: null,
  },
});

const emits = defineEmits([
  'close',
]);

const cryptoStore = useCryptoStore();
const {
  rates,
} = storeToRefs(cryptoStore);

const uiStore = useUiStore();
const {
  walletModalFundsContext,
} = storeToRefs(uiStore);
const {
  showTxToastNotification,
} = uiStore;

const bankingStore = useBankingStore();
const {
  swapsPending,
} = storeToRefs(bankingStore);
const {
  requestWithdrawal,
  getWithdrawalPaymentMethods,
} = bankingStore;

const userStore = useUserStore();
const {
  userFeatures,
} = storeToRefs(userStore);

const locationStore = useLocationStore();
const {
  isWithdrawalRestricted,
} = storeToRefs(locationStore);

const websocketStore = useWebsocketStore();

const {
  validateAddress,
  fetchIsWithdrawalAvailable,
  fetchPendingWithdrawals,
  hasLocalPendingWithdrawals,
  paymentMethodType,
} = useWallet();
const {
  $formatMoney,
  $truncateNumber,
  $convertExponentialNumber,
  $preventLetterInput,
} = useNuxtApp();

const formDisabled = ref(false);
const custodialWithdrawalAddress = ref('');
const isCustodialWithdrawalValid = ref(false);
const custodialWithdrawalTag = ref('');
const transferAmount = ref('');
const transferAmountOutput = computed(() => $truncateNumber((transferAmount.value || 0) * (rates.value?.[props.selectedCurrency?.code]?.USD), 2));
const totalTransferAmount = computed(() => new BigNumber(transferAmount.value || 0).multipliedBy(new BigNumber(props.selectedCurrency?.factor)).toNumber());
const isLoading = ref(true);
const errorMessage = ref('');
const txHash = ref('');
const withdrawalAvailabilityDetails = ref();
const canUserWithdraw = ref(userFeatures.value?.withdraw);
const minSwap = computed(() => withdrawalAvailabilityDetails.value?.minimumAmount || props.currentBalance?.config?.deposit?.minAmount);
const maxSwap = computed(() => {
  if (withdrawalAvailabilityDetails.value?.limitRemaining) {
    return Math.min(
      withdrawalAvailabilityDetails.value?.limitRemaining,
      props.currentBalance.value
    );
  } else {
    return withdrawalAvailabilityDetails.value?.availableAmount || 5;
  }
});
const maxSwapErrorMsg = computed(() => {
  switch (true) {
    case withdrawalAvailabilityDetails.value?.limit === null:
      return '';
    case new BigNumber(props.currentBalance?.value).isLessThan(withdrawalAvailabilityDetails.value?.limit):
      return `Maximum withdrawal is ${maxSwap.value} ${props.selectedCurrency?.code.toUpperCase()}`;
    default:
      return `Withdrawal is limited to ${withdrawalAvailabilityDetails.value?.limit} ${props.selectedCurrency?.code.toUpperCase()} per 24h. Please select a lower amount or try again later.`;
  }
});
const validationSchema = computed(() => {
  const shape = {
    transferAmount: yup
    .number()
    .positive()
    .min(minSwap.value, `Minimum withdrawal is ${minSwap.value} ${props.selectedCurrency?.code.toUpperCase()}`)
    .max(maxSwap.value, maxSwapErrorMsg.value)
    .required('Please enter a numeric value')
    .typeError('Please enter a numeric value')
    .label('Amount'),
  };

  if (!custodialWithdrawalAddress.value) {
    shape.custodialWithdrawalAddress = yup
    .string()
    .required('Please enter a wallet address')
    .test('custodialWithdrawalValid', 'Please enter a valid wallet address', () => isCustodialWithdrawalValid.value)
    .label('Wallet Address');
  }
  return yup.object().shape(shape);
});
const walletAddressOptions = ref([]);
const selectedWalletAddressOption = ref();
const isWalletAddressOptionsLoading = ref(false);
const fee = computed(() => new BigNumber(withdrawalAvailabilityDetails.value?.fee?.amount || 0).toNumber());
const isWithdrawalProcessing = ref(false);
const isWithdrawalComplete = ref(false);
const transferOutputAmount = computed(() => new BigNumber(transferAmount.value || 0).minus(new BigNumber(fee.value)).toNumber());
const canSubmit = computed(() =>
  !formDisabled.value
  && isCustodialWithdrawalValid.value
  && (props.currentBalance.value > 0)
  && (transferOutputAmount.value > 0)
  && !isWithdrawalProcessing.value
);
const emailVerificationRequired = computed(() => {
  return withdrawalAvailabilityDetails.value?.outcome === 'VerifiedEmailRequired' || false;
});

const disableFormActions = computed(() => {
    if (withdrawalAvailabilityDetails.value?.outcome === 'VerificationRequired')
      return true;

    if (withdrawalAvailabilityDetails.value?.outcome === 'VerifiedEmailRequired')
      return true;

    if (withdrawalAvailabilityDetails.value?.outcome === 'DepositRequirementNotMet')
      return true;

    return false;
});

onMounted(async() => {
  await handleCurrencyChange();
  isLoading.value = false;
});

websocketStore.$onAction(({ name, args, }) => {
  if (name === 'handleMessage' && args[0].type === 'Withdrawal:StatusChange') {
    useMessageHandler({ name, args, }, handleWebsocketWithdrawalUpdate, 'Withdrawal:StatusChange');
  }
});

async function handleCurrencyChange() {
  if (!canUserWithdraw.value || isWithdrawalRestricted.value) {
    formDisabled.value = true;
  } else {
    isWithdrawalProcessing.value = hasLocalPendingWithdrawals(props.selectedCurrency?.code);
    const response = await fetchPendingWithdrawals(props.selectedCurrency?.code);

    if (!response.items?.length && !isWithdrawalProcessing.value) {
    await Promise.all([
      fetchPreviouslyUsedWithdrawalAddresses(),
      handleFetchIsWithdrawalAvailable()
    ]);
    } else {
      formDisabled.value = true;
      isWithdrawalProcessing.value = true;
    }
  }
}

async function handleSubmitCustodialWithdrawl() {
  if (!isLoading.value && canSubmit.value) {
    isLoading.value = true;

    const type = paymentMethodType(props.activeNetworkOption?.name);

    const data = {
      amount: transferAmount.value.toString(),
      context: walletModalFundsContext.value,
      paymentMethod: {
        type,
        providerRef: custodialWithdrawalAddress.value,
      },
    };

    if (custodialWithdrawalTag.value && props.selectedCurrency?.code) {
      switch (props.selectedCurrency?.code) {
        case 'XRP':
        case 'EOS':
          data.tag = custodialWithdrawalTag.value;
          break;
      }
    }

    try {
      const response = await requestWithdrawal(
        props.selectedCurrency?.code,
        data.amount,
        data?.paymentMethod,
        data.context,
        props.activeNetworkOption?.name,
        data?.tag
      );
      if (response.status === 'Pending' && withdrawalDetails.value?.requireConfirm) {
        showTxToastNotification('RequireConfirm', 'Withdrawal', data.context);
      }
    } catch (err) {
      if (err.message !== 'Withdrawal unavailable.') {
        errorMessage.value = err.message;
      }
    } finally {
      isLoading.value = false;
    }
  }
}

async function fetchPreviouslyUsedWithdrawalAddresses() {
  isWalletAddressOptionsLoading.value = true;
  try {
    custodialWithdrawalAddress.value = '';
    const provider = walletModalFundsContext.value === 'Custodial' ? 'Fireblocks' : 'Ethereum';
    const methods = await getWithdrawalPaymentMethods([props.activeNetworkOption?.name,], [provider,]);
    walletAddressOptions.value = methods.map(x => ({ value: x?.address, text: x?.address, }));
    selectedWalletAddressOption.value = { value: '0', text: 'Choose existing address', };
  } catch (err) {
    console.log('fetchPreviouslyUsedWithdrawalAddresses failed:::', err);
  } finally {
    isWalletAddressOptionsLoading.value = false;
  }
}

function handleAddressChange(values) {
  if (values.custodialWithdrawalAddress.length) {
    custodialWithdrawalAddress.value = values.custodialWithdrawalAddress;
    isCustodialWithdrawalValid.value = validateAddress(custodialWithdrawalAddress.value, props.activeNetworkOption?.name);
  }
}

function handleTagChange(values) {
  custodialWithdrawalTag.value = values.custodialWithdrawalTag;
}

function handleTranferAmountChange(values) {
  transferAmount.value = values.transferAmount > 0 ? $convertExponentialNumber(values.transferAmount) : 0;
}

function handleSelectWalletAddressOption(option) {
  if (!option) {
    selectedWalletAddressOption.value = { value: '0', text: 'Choose existing address', };
  } else {
    selectedWalletAddressOption.value = {
      value: option.value,
      text: option.text,
    };
    handleAddressChange({ custodialWithdrawalAddress: option.value, });
  }
}

async function handleFetchIsWithdrawalAvailable() {
  try {
    const response = await fetchIsWithdrawalAvailable(props.selectedCurrency?.code, transferAmount.value || undefined);
    withdrawalAvailabilityDetails.value = response;
  } catch (err) {
    console.log('handleFetchIsWithdrawalAvailable failed:::', err);
  }
}

async function handleWebsocketWithdrawalUpdate(payload) {
  if (['Confirmed', 'Approved',].includes(payload.status)) {
    emits('close');
  } else if (['Cancelled', 'Declined', 'Error', 'InsufficientFunds',].includes(payload.status)) {
    isWithdrawalProcessing.value = false;
  } else if (payload.status === 'Complete') {
    isWithdrawalProcessing.value = false;
    isWithdrawalComplete.value = true;
    await resetRequestValue();
  }

  txHash.value = payload?.providerRef;
}

async function resetRequestValue() {
  transferAmount.value = '';
  await handleFetchIsWithdrawalAvailable();
}

watch(
  () => props.selectedCurrency,
  async() => {
    await handleCurrencyChange();
  }
);

watch(
  () => props.activeNetworkOption,
  async() => {
    await fetchPreviouslyUsedWithdrawalAddresses();
  }
);

watch(
  () => swapsPending.value,
  () => {
    isWithdrawalProcessing.value = hasLocalPendingWithdrawals(props.selectedCurrency?.code);
  }
);
</script>
