<template>
  <div class="method-selector bg-black p-1 rounded-lg text-slate flex gap-2">
    <template
      v-for="option in options"
      :key="option.value"
    >
      <button
        v-if="!option.hide"
        type="button"
        class="flex-1 py-2 text-bold text-sm text-slate-200"
        :class="{ 'bg-slate-500 text-white rounded-md' : option.isActive }"
        @click.prevent="emits('select', option.value)"
      >
        {{ option.label }}
      </button>
    </template>
  </div>
</template>

<script setup>
defineProps({
  options: {
    type: Array,
    default() {
      return [];
    },
  },
});

const emits = defineEmits([
  'select',
]);
</script>
