<template>
  <div class="tx-history-link flex justify-center rounded-md">
    <ButtonLink
      theme="loadmore"
      size="sm"
      :to="{ path: '/wallet', query: { view: 'Transactions' } }"
      class="font-semibold text-center hover:bg-slate-700 rounded-md underline"
    >
      Transaction History
    </ButtonLink>
  </div>
</template>
