<template>
  <ButtonButton
    :theme="isBuyOnly ? 'primary' : 'grey'"
    class="w-full my-5 flex justify-center font-normal"
    :disabled="isDisabled"
    @click.prevent="emits('start-moonpay-payment')"
  >
    Deposit {{ currencyName }} with
    <SvgIcon
      icon="moonpay"
      width="90"
      height="26"
      class="my-auto ml-2"
    />
  </ButtonButton>
</template>

<script setup>
defineProps({
  currencyName: {
    type: String,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isBuyOnly: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['start-moonpay-payment',]);
</script>
